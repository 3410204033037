<template>
  <div>
   
    <div>
      {{data.label}}
      <span v-if="data.description">
        <i class="el-icon-info" :title="data.description" />
      </span>
      
    </div>
    <div>
      <el-select
        v-if="data.global_variable_type == 'USERS'"
        :style="getStyle"
        v-model="selectedValue"
        :placeholder="data.placeholder"
      >
        <el-option
          v-for="(user,index) of getAllUsers"
          :value="user._id"
          :key="index"
          :label="getPrimaryDataForUser(user)"
        ></el-option>
      </el-select>
      <el-select
        v-if="data.global_variable_type == 'ENTITY' && checkSelectType('single')"
        :style="getStyle"
        v-model="selectedValue"
        :placeholder="data.placeholder"
      >
        <el-option
          v-for="(entity,index) of entities"
          :value="entity._id"
          :key="index"
          :label="getPrimaryDataForEntity(entity)"
        ></el-option>
      </el-select>
      <el-select
        v-if="data.global_variable_type == 'ENTITY' && checkSelectType('multiple')"
        :style="getStyle"
        v-model="selectedMultipleValue"
        multiple
        filterable
        :placeholder="data.placeholder"
      >
        <el-option
          v-for="(entity,index) of entities"
          :value="entity._id"
          :key="index"
          :label="getPrimaryDataForEntity(entity)"
        ></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
   name:"GlobalVariableView",
  components: {},
  props: ["data", "value"],
  computed: {
    ...mapGetters("users", ["getUsersList"]),
    ...mapGetters("companyEntities", ["getAllCompanyEntitiesData"]),
    ...mapGetters("entitiesData", ["getAllEntitiesData"]),

    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    getStyle() {
      return `height:${this.data.height - 30}px`;
    },
    getAllUsers() {
      return this.getUsersList && this.getUsersList.data
        ? this.getUsersList.data
        : [];
    },
    getAllEntities() {
      return this.getAllEntitiesData && this.getAllEntitiesData.data
        ? this.getAllEntitiesData.data
        : [];
    },
    getPrimaryDataForUser() {
      return user => {
        let primaryFields = user.user_type.allowed_fields.filter(
          e => e.primary
        );

        let data = [];

        primaryFields.forEach(field => {
          data.push(user[field.key]);
        });

        return data.join(" ");
      };
    },
    getPrimaryDataForEntity() {
      return entity => {
        let primaryFields = entity.entity_id.primary_fields || [];

        let data = [];
        primaryFields.forEach(field => {
          if (entity.entity_data) {
            data.push(entity.entity_data[field.key]);
          }
        });

        return data.join(" ");
      };
    }
  },
  data() {
    return {
      validations: [],
      options: [],
      entities: [],
      selectedMultipleValue: []
    };
  },
  mounted() {
    this.options = this.data.options || [];

    this.fetchOptions();
  },
  methods: {
    async fetchOptions() {
      let userType = this.data.global_variable_user_type;

      let params = {
        user_type: userType,
        get_all: true
      };
      if (this.data.global_variable_type == "USERS") {
        await this.$store.dispatch("users/fetchAllUsers", params);
      } else {
        let params = {
          get_all: true,
          entity_id: this.data.global_variable_entity_type,
          filters:
            this.data.filters && this.data.filters.length
              ? this.data.filters
              : []
        };
        await this.$store.dispatch("entitiesData/getEntitiesData", params);
        this.entities = [...this.getAllEntities];
      }
    },
    checkSelectType(type) {
      if (this.data && this.data.global_variable_entity_select_type) {
        if (this.data.global_variable_entity_select_type == 'single') {
          return type == 'single'
        }

        if (this.data.global_variable_entity_select_type  == 'multiple') {
          return type == 'multiple'
        }
      } else {
        return type == 'single'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;
  .el-input,
  .el-input__inner {
    height: inherit !important;
  }
}
</style>